.AnnotatedCurveControl {
  background: #d0d0d0;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-top: 20px;
  width: 500px;
  height: 250px;
  user-select: none;
  justify-content: flex-start;
  touch-action: none;

  svg {
    cursor: pointer;
    height: 100%;
  }

  text.angle-label {
    fill: green;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bolder;
    text-anchor: middle;
  }

  text.plot-title {
    alignment-baseline: top;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
    fill: black;
    text-anchor: middle;
  }

  text.vertical-line-label {
    alignment-baseline: middle;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bolder;
  }

  text.active-change-description {
  }

  rect.active-change-description {
    fill: lightgray;
  }

  path.horizontal-line {
    stroke: green;
    stroke-width: 4;
  }

  path.vertical-line {
    stroke-width: 4;
  }

  path.curve {
    fill: none;
    stroke: black;
    stroke-width: 2;
  }

  circle.knob {
    fill: #a5a3a3;
    stroke: black;
    stroke-width: 1;
  }
}
