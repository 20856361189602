.UnitCircleControl {
  background: #d0d0d0;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-top: 20px;
  width: 300px;
  height: 300px;
  user-select: none;
  justify-content: flex-start;
  touch-action: none;

  svg {
    cursor: pointer;
  }

  text.angle-label {
    alignment-baseline: bottom;
    font-family: sans-serif;
    font-size: 8px;
    font-weight: bolder;
    fill: green;
  }

  text.plot-title {
    alignment-baseline: top;
    font-family: sans-serif;
    font-size: 8px;
    font-weight: bold;
    fill: black;
    text-anchor: middle;
  }

  text.vertical-line-label {
    alignment-baseline: middle;
    font-family: sans-serif;
    font-size: 8px;
    font-weight: bolder;
    fill: orange;
  }

  text.horizontal-line-label {
    alignment-baseline: middle;
    font-family: sans-serif;
    font-size: 8px;
    font-weight: bolder;
    fill: blue;
    text-anchor: middle;
  }

  text.active-change-description {
  }

  rect.active-change-description {
    fill: lightgray;
  }

  circle.background-circle {
    stroke: black;
    fill: none;
  }

  path.horizontal-line {
    stroke: blue;
    stroke-width: 1.5;
  }

  path.vertical-line {
    stroke: orange;
    stroke-width: 1.5;
  }

  path.radius-line {
    stroke: white;
    stroke-width: 1.5;
  }

  path.arc {
    fill: none;
    stroke: green;
    stroke-width: 2;
  }

  circle.knob {
    fill: #a5a3a3;
    stroke: black;
    stroke-width: 0.5;
  }
}
