.TimePlot {
  background: #d0d0d0;
  display: flex;
  flex-grow: 0;
  margin-top: 10px;

  svg {
    height: 100%;
    width: 100%;

    path.curve {
      fill: none;
      stroke: black;
      stroke-width: 1;
    }

    path.grid-line {
      stroke: white;
    }

    text.bottom-label {
      alignment-baseline: bottom;
      font-weight: bolder;
      text-anchor: middle;
    }

    text.x-axis-label {
      alignment-baseline: top;
      font-weight: bolder;
      text-anchor: middle;
    }

    text.y-axis-label {
      alignment-baseline: middle;
      font-weight: bolder;
      text-anchor: end;
    }
  }
}
