.FrequencyDomainControl {
  background: #d0d0d0;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  margin-top: 20px;
  width: 800px;
  user-select: none;
  justify-content: flex-start;
  touch-action: none;

  text.active-change-description {
  }

  text.bottom-label {
    font-weight: bolder;
  }

  rect.active-change-description {
    fill: lightgray;
  }

  svg {
    rect.knob {
      cursor: pointer;
      fill: #a5a3a3;
      stroke: black;
      rx: 4;
    }

    rect.channel {
      cursor: pointer;
      fill: #efefef;
      stroke: #555454;
    }

    rect.channel-background {
      cursor: pointer;
      fill: #d0d0d0;
      stroke: none;
    }
  }
}
