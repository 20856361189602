.StepsPlot {
  path.grid-line {
    stroke: grey;
  }

  path.diagonal {
    stroke: grey;
  }

  path.current-diagonal,
  line.current-diagonal {
    stroke: black;
    stroke-width: 2;
  }

  text.axis-label {
    alignment-baseline: middle;
    text-anchor: end;
  }

  line.multiplication-sign {
    stroke: black;
    stroke-width: 3;
  }

  rect.plus-sign-background {
    stroke: black;
    stroke-width: 1;
    fill: white;
  }
  rect.multiplication-sign-background {
    stroke: black;
    stroke-width: 1;
    fill: white;
  }

  line.plus-sign {
    stroke: black;
    stroke-width: 3;
  }

  circle.current-signal-value {
    stroke: blue;
    fill: blue;
  }

  circle.signal-value {
    stroke: black;
    fill: black;
  }

  circle.unused-signal-value {
    stroke: none;
    fill: #c3c3c3;
  }

  circle.current-kernel-value {
    stroke: green;
    fill: green;
  }

  circle.previous-out-signal-value {
    stroke: none;
    fill: #c3c3c3;
  }

  circle.current-out-signal-value {
    stroke: orange;
    fill: orange;
  }

  circle.future-out-signal-value {
    stroke: black;
    fill: none;
  }
}
