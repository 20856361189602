.TopNav {
  display: flex;
  font-size: 0.8rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px 0 5px 0;
  text-align: center;
  width: 95%;

  .left-nav-link,
  .right-nav-link,
  .center-text {
    display: flex;
  }

  .center-text {
    margin-top: 12px;
    font-size: 0.7rem;
    font-weight: lighter;
  }

  a {
    color: #0366d6;
    font-size: 1rem;
    margin: 0 5px;
    padding: 5px 5px;
    text-decoration: none;
  }

  a:hover {
    background: #ced2d9;
    border-radius: 6px;
  }
}
