.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  min-height: 98vh;
  font-family: sans-serif;

  h1,
  h2,
  h3,
  h4 {
    text-align: left;
    margin: 10px 0 0 0;
  }

  ul li {
    padding-bottom: 4px;
  }

  a {
    color: #0366d6;
    cursor: pointer;
  }

  a:active {
    color: #01438d;
  }

  input[type='number'] {
    font-size: 1.5rem;
    margin-right: 10px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }

  svg {
    user-select: none;
  }
}
